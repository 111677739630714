import $axios from '../plugins/axios'

class LessonService {
	
	getAll () {
		
		return $axios.get('get-lessons').then((res) => {
			
			return res.data
		}).catch((error) => {
			
			throw error
		})
	}
	getOne (id) {
		
		return $axios.get('get-lesson/' + id)
		.then((res) => {
			
			return res.data
		}).catch((error) => {
			
			throw error
		})
	}
	
	create (data) {
		return $axios.post(`create-lesson`, data)
		.then((res) => {
			return res.data
		})
		.catch((error) => {
			throw error
		})
	}
	
	update (data) {
		return $axios.post(`update-lesson`, data)
		.then((res) => {
			return res.data
		})
		.catch((error) => {
			throw error
		})
	}
	
	delete (data) {
		return $axios.post(`delete-lesson`, data)
		.then((res) => {
			return res.data
		})
		.catch((error) => {
			throw error
		})
	}
}

export default new LessonService()

<template>
  <v-container class="">

    <v-row>
      <v-col>
        <v-text-field
          id="name-field"
          v-model="question"
          clearable
          label="Question"
          required
        />
        <v-text-field
          id="name-field"
          v-model="option"
          clearable
          label="Option (Be careful with this field. Logic on the backend depends on it.)"
          required
        />
        <v-select
          id="name-field"
          v-model="questionTypeId"
          :items="questionTypes"
          item-text="name"
          item-value="id"
          clearable
          label="Select question type"
          required
        />
        <div v-if="questionTypeId === 2">
          <div v-for="(choice, index) in choices" :key="index">

            <v-row>
              <v-col cols="4">
                <v-text-field
                    v-model="choices[index]"
                />
              </v-col>
              <v-col cols="4">
                <v-btn @click="removeChoice(index)">Remove</v-btn>
              </v-col>
            </v-row>
          </div>
          <v-btn @click="addChoice">Add choice</v-btn>
        </div>
        <div class="text-right">
          <v-btn @click="submitForm">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import AppButton from '@/components/app/AppButton.vue'
import QuestionTypeService from '@/services/question-type.service'

export default {
  name: 'AccountSetupQuestionFormView',

  components: {},
  props: {
    accountSetupQuestionProp: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data () {
    return {
      id: null,
      question: null,
      option: null,
      choices: [],
      questionTypeId: 1,
      updateMode: false,
      questionTypes: [],
    }
  },

  computed: {},

  methods: {
    removeChoice(index){
      this.choices.splice(index, 1);
    },
    addChoice(){
      this.choices.push('');
    },
    submitForm () {

      this.$emit('submitForm', {
        id: this.id,
        question: this.question,
        option: this.option,
        choices: this.choices,
        question_type_id: this.questionTypeId,
      })
    },
    updateFile (file) {
      this.file = file
    },
    loadQuestionTypes () {

      QuestionTypeService.getAll().then((res) => {
        this.questionTypes = res
      })
    },
  },
  created () {
    this.loadQuestionTypes();
    
    if (this.accountSetupQuestionProp) {

      this.id = this.accountSetupQuestionProp.id
      this.question = this.accountSetupQuestionProp.question
      this.option = this.accountSetupQuestionProp.option
      this.questionTypeId = this.accountSetupQuestionProp.question_type_id
      this.choices = this.accountSetupQuestionProp.choices

      this.updateMode = true
    }
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
</style>

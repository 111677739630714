<template>
  <v-container class="">

    <v-row v-if="!isLoading">
      <v-col>
        <v-text-field
            id="name-field"
            v-model="name"
            clearable
            label="Name"
            required
        />
        <v-text-field
            id="name-field"
            v-model="summary"
            clearable
            label="Summary"
            required
        />
        <v-textarea
            id="info-field"
            v-model="info"
            clearable
            label="info"
            required
        />
        <v-row>
          <v-col cols="6">
            <v-row class="journeys-box">
              <div>Available journeys:</div>
              <div>(Click on the journey you would like to add to this destination)</div>

              <v-col v-for="(journey, index) in availableJourneys" :key="index" cols="3">
                <v-btn @click="addJourney(journey, index)">
                  {{ journey.name }}
                </v-btn>
              </v-col>

            </v-row>
          </v-col>
          <v-col cols="6">

            <v-row class="journeys-box">
              <div>Selected journeys:</div>
              <draggable v-model="selectedJourneys">
                <transition-group>
                  <div v-for="(element, index) in selectedJourneys" :key="element.id"
                       style="margin: 10px; border: 1px;">
                    {{ element.name }}
                    <v-btn @click="removeJourney(element, index)">
                      <v-icon> mdi-trash-can</v-icon>
                    </v-btn>
                  </div>
                </transition-group>
              </draggable>
            </v-row>

          </v-col>
        </v-row>
        <div class="text-right" style="margin-top: 40px;">
          <v-btn @click="submitForm">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import AppButton from '@/components/app/AppButton.vue'
import draggable from 'vuedraggable'
import DestinationService from '@/services/destination.service'
import JourneyService from '@/services/journey.service'

export default {
  name: 'DestinationFormView',

  components: {
    draggable,
  },
  props: {
    destinationProp: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      id: null,
      name: null,
      summary: null,
      info: null,
      availableJourneys: null,
      selectedJourneys: [],
      isLoading: true,
    }
  },

  computed: {},

  methods: {
    submitForm() {

      let selectedJourneysIds = this.selectedJourneys.map(function (obj) {
        return obj.id
      })

      this.$emit('submitForm', {
        id: this.id,
        name: this.name,
        summary: this.summary,
        info: this.info,
        selected_journeys: selectedJourneysIds,
      })
    },
    loadAvailableJourneys() {

      if (this.destinationProp) {

        DestinationService.getAvailableJourneys(this.destinationProp.id).then((res) => {
          this.availableJourneys = res
          this.isLoading = false
        })
      } else {

        JourneyService.getAll().then((res) => {
          this.availableJourneys = res
          this.isLoading = false
        })
      }
    },
    addJourney(journey, index) {

      this.selectedJourneys.push(journey)
      this.availableJourneys.splice(index, 1);
    },
    removeJourney(journey, index) {

      journey.destination_id = null

      this.availableJourneys.push(journey)
      this.selectedJourneys.splice(index, 1);
    },
  },
  created() {

    if (this.destinationProp) {

      this.id = this.destinationProp.id
      this.name = this.destinationProp.name
      this.summary = this.destinationProp.summary
      this.info = this.destinationProp.info
      this.selectedJourneys = this.destinationProp.journeys

      this.updateMode = true
    }

    this.loadAvailableJourneys()
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}

.journeys-box {
  border: 1px solid;
  padding: 20px;
}
</style>

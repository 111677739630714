import $axios from '../plugins/axios'

class UserService {
  getAllUsers () {
    return $axios.get(`/get-users`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getAllInvitedUsers () {
    return $axios.get(`/get-invitations`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getOneUser (id) {
    return $axios.get(`/get-user/${ id }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  verifyEmail (data) {
    return $axios.post('/verify-email', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeUserImage (data) {
    return $axios.post('/change-user-image', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeUserName (data) {
    return $axios.post('/change-user-name', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeUserPosition (data) {
    return $axios.post('/change-user-company-position', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeUserCompany (data) {
    return $axios.post('/change-user-company', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeUserProducts (data) {
    return $axios.post('/change-user-products', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  deleteUser (data) {
    return $axios.post('/delete-user', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  checkInvitationExists (data) {
    return $axios.post('/check-invitation-exists', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  activateUser (data) {
    return $axios.post('/activate-user', data)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
  deactivateUser (data) {
    return $axios.post('/deactivate-user', data)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

}

export default new UserService()

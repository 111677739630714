import Vue from 'vue'
import Vuex from 'vuex'
import snackbar from "./modules/snackbar"
import auth from "./modules/auth"
import user from '@/store/modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    snackbar,
    user
  }
})

import $axios from '../plugins/axios'

class JourneyService {
	
	getAll () {
		
		return $axios.get('get-journeys').then((res) => {
			
			return res.data
		}).catch((error) => {
			
			throw error
		})
	}
	getOne (id) {
		
		return $axios.get('get-journey/' + id)
		.then((res) => {
			
			return res.data
		}).catch((error) => {
			
			throw error
		})
	}
	
	getAvailableLessons (id) {
		
		return $axios.get('get-journey-available-lessons/' + id)
		.then((res) => {
			
			return res.data
		}).catch((error) => {
			
			throw error
		})
	}
	
	create (data) {
		return $axios.post(`create-journey`, data)
		.then((res) => {
			return res.data
		})
		.catch((error) => {
			throw error
		})
	}
	
	update (data) {
		return $axios.post(`update-journey`, data)
		.then((res) => {
			return res.data
		})
		.catch((error) => {
			throw error
		})
	}
	
	delete (data) {
		return $axios.post(`delete-journey`, data)
		.then((res) => {
			return res.data
		})
		.catch((error) => {
			throw error
		})
	}
}

export default new JourneyService()

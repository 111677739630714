<template>
  <v-container class="">
    <v-row>
      <v-col cols="9">
        <span class="grey--text">Account setup questions</span>
      </v-col>
      <v-col cols="3">
        <v-btn @click="redirectToCreate">Create account setup question</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>

        <v-row>
          <v-col cols="1">
            ID
          </v-col>
          <v-col cols="5">
            Question
          </v-col>
          <v-col cols="3">
            Option
          </v-col>
          <v-col cols="3">
            Actions
          </v-col>
        </v-row>

        <draggable v-model="accountSetupQuestions"
                   @end="reorderQuestions">
          <transition-group>
            <v-row v-for="(question) in accountSetupQuestions" :key="question.id">
              <v-col cols="1">
                {{ question.id }}
              </v-col>
              <v-col cols="5">
                {{ question.question }}
              </v-col>
              <v-col cols="3">
                {{ question.option }}
              </v-col>
              <v-col cols="3">
                <v-row>
                  <v-col cols="4">
                    <v-btn color="warning" @click="redirectToUpdate(question.id)">Update</v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn color="error" @click="deleteAccountSetup(question.id)">Delete</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </transition-group>
        </draggable>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import AppButton from '@/components/app/AppButton.vue'
import AccountSetupQuestionService from '@/services/account-setup-question.service'
import draggable from 'vuedraggable'

export default {
  name: 'AccountSetupView',

  components: {
    draggable
  },

  data () {
    return {
      accountSetupQuestions: null,
    }
  },

  computed: {},

  methods: {
    reorderQuestions() {
      console.log(this.accountSetupQuestions)
      AccountSetupQuestionService.reorder(this.accountSetupQuestions).then(() => {

        this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Account setup questions reordered successfully.',
          duration: 4000,
          mode: 'success'
        })

        this.loadAccountSetupQuestions()
      })
    },
    redirectToCreate () {
      this.$router.push(`/create-account-setup-question`)
    },
    redirectToUpdate (id) {
      this.$router.push(`/update-account-setup-question/` + id)
    },
    deleteAccountSetup (id) {
      if (confirm('Are you sure you want to delete this account setup question?')) {
        AccountSetupQuestionService.delete({id}).then(() => {

          this.$store.dispatch('snackbar/showSnackbarMessage', {
            message: 'Account setup question deleted successfully.',
            duration: 4000,
            mode: 'success'
          })
          
          this.loadAccountSetupQuestions()
        })
      }
    },
    loadAccountSetupQuestions () {
      AccountSetupQuestionService.getAll().then((res) => {
        this.accountSetupQuestions = res
      })
    },
  },
  created () {
    this.loadAccountSetupQuestions()
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
</style>

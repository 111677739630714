<template>
  <v-container class="">
      <journey-form-view @submitForm="updateJourney" v-if="journey" :journey-prop="journey"></journey-form-view>
  </v-container>
</template>

<script>
// import AppButton from '@/components/app/AppButton.vue'
import JourneyFormView from '@/views/journeys/JourneyFormView.vue'
import JourneyService from '@/services/journey.service'

export default {
  name: 'UpdateJourneyView',

  components: {
    JourneyFormView
  },

  data () {
    return {
      journey: null
    }
  },

  computed: {},

  methods: {

    updateJourney (data) {

      JourneyService.update(data).then((res) => {
        this.accountSetupQuestions = res

        this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Journey updated successfully.',
          duration: 4000,
          mode: 'success'
        })
        
        this.$router.push(`/journeys`)
      })
    },
    loadJourney(id){

      JourneyService.getOne(id).then((res) => {
        this.journey = res
      })
    }
  },
  mounted () {
    this.loadJourney(this.$route.params.id)
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
</style>

<template>
  <div class="component-wrap main-div-pp">
    <div class="header">

      <div class="logo-container">
        <v-img src="@/assets/logo.png" width="100" class="mx-auto"></v-img>
      </div>
      <div style="text-align: center; margin-top: 30px;">
        <v-img src="@/assets/lovemap-logo-text.png" width="200" class="mx-auto"></v-img>
      </div>
    </div>

    <div class="text-container">
      <h2>Congratulations!</h2>
      <p>You’ve been verified.</p>
      <p>You can return to the app.</p>
<!--      <v-btn-->
<!--        id="back-to-app-btn"-->
<!--        color="primary"-->
<!--        raised-->
<!--        rounded-->
<!--        type="submit"-->
<!--        width="200"-->
<!--        x-large-->
<!--      >-->
<!--        Back to app-->
<!--      </v-btn>-->
    </div>
  </div>
</template>

<script>
import UserService from '@/services/user.service'

export default {
  name: 'PrivacyPolicy',

  mounted () {
    console.log(this.$route.query)
    let id = this.$route.query.id
    let hash = this.$route.query.hash

    UserService.verifyEmail({id, hash})
  },
}
</script>

<style lang="scss" scoped>

.logo-container {
}

.text-container {
  text-align: center;
  padding-top: 20px;
}

.header {
  background-image: linear-gradient(#FFFFFF, #F4EDFD) !important;
  padding-top: 100px;
  padding-bottom: 40px;
}

#back-to-app-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
</style>

<template>
  <v-container class="">
      <intro-screen-form-view @submitForm="createIntroScreen"></intro-screen-form-view>
  </v-container>
</template>

<script>
// import AppButton from '@/components/app/AppButton.vue'
import IntroScreenFormView from '@/views/intro-screens/IntroScreenFormView.vue'
import IntroScreenService from '@/services/intro-screen.service'

export default {
  name: 'CreateIntroScreenView',

  components: {
    IntroScreenFormView
  },

  data () {
    return {
    }
  },

  computed: {},

  methods: {
    
    createIntroScreen (data) {

      console.log(data)
      
      let bodyFormData = new FormData()

      bodyFormData.set('title', data.title)
      bodyFormData.set('text', data.text)
      bodyFormData.set('file', data.file)
      bodyFormData.set('layout_id', data.layout_id)

      IntroScreenService.create(bodyFormData).then(() => {

        this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Intro screen created successfully.',
          duration: 4000,
          mode: 'success'
        })
        
        this.$router.push(`/intro-screens`)
      })
    }
  },
  created () {
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
</style>

<template>
  <v-container class="">
      <account-setup-question-form-view @submitForm="create"></account-setup-question-form-view>
  </v-container>
</template>

<script>
// import AppButton from '@/components/app/AppButton.vue'
import AccountSetupQuestionFormView from '@/views/account-setup/AccountSetupQuestionFormView'
import AccountSetupQuestionService from '@/services/account-setup-question.service'

export default {
  name: 'CreateAccountSetupQuestionView',

  components: {
    AccountSetupQuestionFormView
  },

  data () {
    return {
    }
  },

  computed: {},

  methods: {

    create (data) {
      
      AccountSetupQuestionService.create(data).then(() => {

        this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Created successfully.',
          duration: 4000,
          mode: 'success'
        })
        
        this.$router.push(`/account-setup`)
      })
    }
  },
  created () {
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
</style>

<template>
  <v-container class="">

    <v-row>
      <v-col>
        <v-text-field
            id="name-field"
            v-model="name"
            clearable
            label="Name"
            required
        />
        <v-textarea
            id="description-field"
            v-model="description"
            clearable
            label="Description"
            required
        />
        <v-text-field
            id="duration-field"
            v-model="duration"
            type="number"
            clearable
            label="Duration"
            required
        />
      </v-col>
    </v-row>
    <v-row>
      Content:
      <v-col cols="6">

        <draggable v-model="content">
          <transition-group>
            <div v-for="(oneContent, index) in content" :key="index"
                 style="margin: 10px; border: 1px solid;">
              <v-col cols="10">
                <v-select
                    id="layout-field"
                    v-model="oneContent.layout_id"
                    :items="layouts"
                    item-text="name"
                    item-value="id"
                    label="Select layout"
                    required
                ></v-select>
                <div v-if="oneContent.layout_id == 1">
                  <v-text-field
                      id="title-field"
                      v-model="oneContent.title"
                      clearable
                      label="Title"
                      required
                  />
                  <v-textarea
                      id="text-field"
                      v-model="oneContent.text"
                      clearable
                      label="Text"
                      required
                  />
                  <v-img width="200" v-if="oneContent.image && oneContent.image.url" :src=oneContent.image.url></v-img>
                  <v-file-input
                      v-model="oneContent.image"
                      :append-icon="'mdi-camera'"
                      :clearable="false"
                      :prepend-icon="''"
                      accept=".png, .jpg, .jpeg"
                  />
                </div>
                <div v-if="oneContent.layout_id == 2">
                  <v-text-field
                      id="title-field"
                      v-model="oneContent.title"
                      clearable
                      label="Title"
                      required
                  />
                  <v-textarea
                      id="text-field"
                      v-model="oneContent.text"
                      clearable
                      label="Text"
                      required
                  />
                </div>
                <div v-if="oneContent.layout_id == 3" style="border: 1px solid blue">

                  <v-col>
                    <v-row v-for="(oneChoice, index) in oneContent.choices" :key="index"
                           style="margin: 10px; border: 1px solid red;">
                      <v-col cols="7">
                        <div>
                          <v-text-field
                              id="title-field"
                              v-model="oneContent.choices[index]"
                              clearable
                              label="Title"
                              required
                          />
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <v-btn @click="removeChoice(oneContent, oneChoice)">Remove</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-btn @click="addChoice(oneContent)">Add choice</v-btn>
                </div>
                <div v-if="oneContent.layout_id == 5">
                  <v-text-field
                      id="title-field"
                      v-model="oneContent.title"
                      clearable
                      label="Title"
                      required
                  />
                  <video v-if="oneContent.video && oneContent.video.url" width="200" controls>
                    <source :src="oneContent.video.url">
                  </video>
                  <v-file-input
                      v-model="oneContent.video"
                      :append-icon="'mdi-video'"
                      :clearable="false"
                      :prepend-icon="''"
                      accept=".mp4, .3gp, .mov, .avi, .mts, .mxf"
                  />
                </div>
                <div v-if="oneContent.layout_id == 6">
                  <v-text-field
                      id="title-field"
                      v-model="oneContent.title"
                      clearable
                      label="Title"
                      required
                  />
                  <video v-if="oneContent.audio && oneContent.audio.url" controls>
                    <source :src="oneContent.audio.url">
                  </video>
                  <v-file-input
                      v-model="oneContent.audio"
                      :append-icon="'mdi-audio'"
                      :clearable="false"
                      :prepend-icon="''"
                  />
                </div>
              </v-col>
              <v-col cols="2">
                <v-btn @click="removeContent(oneContent)">Remove</v-btn>
              </v-col>
            </div>
          </transition-group>
        </draggable>

        <v-btn @click="addContent">Add content</v-btn>
      </v-col>
    </v-row>
    <div class="text-right">
      <v-btn @click="submitForm">Save</v-btn>
    </div>
  </v-container>
</template>

<script>
// import AppButton from '@/components/app/AppButton.vue'
import draggable from 'vuedraggable'
import LayoutService from "@/services/layout.service";

export default {
  name: 'LessonFormView',

  components: {
    draggable
  },
  props: {
    lessonProp: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      id: null,
      name: null,
      description: null,
      duration: null,
      layouts: null,
      content: [],
    }
  },

  computed: {},

  methods: {
    removeContent(content) {
      this.content = this.content.filter(item => item.id !== content.id)
    },
    addContent() {
      this.content.push({
        title: '',
        text: '',
        image: null,
        video: null,
        choices: [],
        layout_id: 1
      })
    },
    addChoice(content) {
      if (!content.choices){
        content.choices = [];
      }
      content.choices.push('')
    },
    removeChoice(content, choice) {

      content.choices = content.choices.filter(item => item !== choice)
    },
    submitForm() {

      this.$emit('submitForm', {
        id: this.id,
        name: this.name,
        description: this.description,
        duration: this.duration,
        content: this.content
      })
    },
    loadLessons() {
      LayoutService.getAll().then((res) => {
        this.layouts = res
      })
    },
  },
  created() {

    this.loadLessons();

    if (this.lessonProp) {

      this.id = this.lessonProp.id
      this.name = this.lessonProp.name
      this.description = this.lessonProp.description
      this.duration = this.lessonProp.duration
      this.content = this.lessonProp.content

      this.updateMode = true
    }
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
</style>

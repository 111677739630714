<template>
  <div>
    <h1 class="mb-6">
      Dashboard
    </h1>

    <v-divider class="mt-1 mb-8" />

    <v-row>

    </v-row>

  </div>
</template>

<script>

export default {
  name: 'DashboardView',

  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    //
  },

  methods: {
   //
  },

  mounted () {
    //
  }
}
</script>

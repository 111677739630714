import { render, staticRenderFns } from "./UpdateJourneyView.vue?vue&type=template&id=034b3b32&scoped=true&"
import script from "./UpdateJourneyView.vue?vue&type=script&lang=js&"
export * from "./UpdateJourneyView.vue?vue&type=script&lang=js&"
import style0 from "./UpdateJourneyView.vue?vue&type=style&index=0&id=034b3b32&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "034b3b32",
  null
  
)

export default component.exports
<template>
  <v-container class="">
    <v-row>
      <v-col cols="9">
        <span class="grey--text">Lessons questions</span>
      </v-col>
      <v-col cols="3">
        <v-btn @click="redirectToCreate">Create lesson</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>

        <v-row>
          <v-col cols="1">
            ID
          </v-col>
          <v-col cols="5">
            Name
          </v-col>
          <v-col cols="3">
            Actions
          </v-col>
        </v-row>

        <v-row v-for="(lesson, index) in lessons" :key="index">
          <v-col cols="1">
            {{ lesson.id }}
          </v-col>
          <v-col cols="5">
            {{ lesson.name }}
          </v-col>
          <v-col cols="4">
            <v-row>
              <v-col cols="5">
                <v-btn color="warning" @click="redirectToUpdate(lesson.id)">Update</v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn color="error" @click="deleteAccountSetup(lesson.id)">Delete</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import AppButton from '@/components/app/AppButton.vue'
import LessonService from '@/services/lesson.service'

export default {
  name: 'LessonListView',

  components: {},

  data () {
    return {
      lessons: null,
    }
  },

  computed: {},

  methods: {
    redirectToCreate () {
      this.$router.push(`/create-lesson`)
    },
    redirectToUpdate (id) {
      this.$router.push(`/update-lesson/` + id)
    },
    deleteAccountSetup (id) {
      if (confirm('Are you sure you want to delete this lesson?')) {
        LessonService.delete({id}).then(() => {

          this.$store.dispatch('snackbar/showSnackbarMessage', {
            message: 'Lesson deleted successfully.',
            duration: 4000,
            mode: 'success'
          })
          
          this.loadLessons()
        })
      }
    },
    loadLessons () {
      LessonService.getAll().then((res) => {
        this.lessons = res
      })
    },
  },
  created () {
    this.loadLessons()
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
</style>

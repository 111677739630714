<template>
  <v-container class="">

    <v-row v-if="!isLoading">
      <v-col>
        <v-text-field
          id="name-field"
          v-model="name"
          clearable
          label="Name"
          required
        />
        <v-textarea
          id="description-field"
          v-model="description"
          clearable
          label="Description"
          required
        />
        <v-row>
          <v-col cols="6">
            <v-row class="lessons-box">
              <div>Available lessons:</div>
              <div>(Click on the lesson you would like to add to this journey)</div>

              <v-col v-for="(lesson, index) in availableLessons" :key="index" cols="12">
                <v-btn @click="addLesson(lesson, index)">
                  {{ lesson.name }}
                </v-btn>
              </v-col>

            </v-row>
          </v-col>
          <v-col cols="6">

            <v-row class="lessons-box">
              <div>Selected lessons:</div>

              <draggable v-model="selectedLessons">
                <transition-group>
                  <div v-for="(element, index) in selectedLessons" :key="element.id" style="margin: 10px; border: 1px;">
                    {{ element.name }}
                    <v-btn @click="removeLesson(element, index)">
                      <v-icon> mdi-trash-can</v-icon>
                    </v-btn>
                  </div>
                </transition-group>
              </draggable>
            </v-row>

          </v-col>
        </v-row>
        <div class="text-right" style="margin-top: 40px;">
          <v-btn @click="submitForm">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import AppButton from '@/components/app/AppButton.vue'
import draggable from 'vuedraggable'
import JourneyService from '@/services/journey.service'
import LessonService from '@/services/lesson.service'

export default {
  name: 'JourneyFormView',

  components: {
    draggable,
  },
  props: {
    journeyProp: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data () {
    return {
      id: null,
      name: null,
      description: null,
      availableLessons: null,
      selectedLessons: [],
      isLoading: true,
    }
  },

  computed: {
  },

  methods: {
    submitForm () {

      let selectedLessonsIds = this.selectedLessons.map(function(obj) {
        return obj.id
      })

      this.$emit('submitForm', {
        id: this.id,
        name: this.name,
        description: this.description,
        selected_lessons: selectedLessonsIds,
      })
    },
    loadAvailableLessons () {
      
      if (this.journeyProp){

        JourneyService.getAvailableLessons(this.journeyProp.id).then((res) => {
          this.availableLessons = res
          this.isLoading = false
        })
      } else {

        LessonService.getAll().then((res) => {
          this.availableLessons = res
          this.isLoading = false
        })
      }      
    },
    addLesson (lesson, index) {

      lesson.journey_id = this.id

      this.selectedLessons.push(lesson)
      
      this.availableLessons.splice(index, 1);
    },
    removeLesson (lesson, index) {

      lesson.journey_id = null

      this.availableLessons.push(lesson)

      this.selectedLessons.splice(index, 1);
    },
  },
  created () {

    if (this.journeyProp) {

      this.id = this.journeyProp.id
      this.name = this.journeyProp.name
      this.description = this.journeyProp.description
      this.selectedLessons = this.journeyProp.lessons

      this.updateMode = true
    }

    this.loadAvailableLessons()
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
.lessons-box {
  border: 1px solid;
  padding: 20px;
}
</style>

import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: {
                    base: '#296DF6',
                    lighten1: '#6096FF',
                    lighten2: '#E8F0FF',
                    darken1: '#2555B1',
                    darken2: '#F4EDFD'
                },
                anchor: '#296DF6',
                // ---
                info: '#3B86FF',
                warning: '#FFB755',
                error: '#F0445F',
                success: '#3CC480',
                grey: {
                    base: '#969696',
                    lighten1: '#C3C3C3',
                    lighten2: '#F0F0F0',
                    lighten3: '#F9F9F9',
                    darken1: '#6F6F6F',
                    darken2: '#444444'
                },
                'tag-important': {
                    base: '#F0445F'
                },
                'tag-cad': {
                    base: '#C9ECDB'
                },
                'tag-document': {
                    base: '#FBE9D0'
                }
            }
        }
    }
})

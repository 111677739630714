import $axios from '../plugins/axios'

class AccountSetupQuestionService {
  
  getAll () {
    
    return $axios.get('get-account-setup-questions')
    .then((res) => {
      
      return res.data
    }).catch((error) => {
      
      throw error
    })
  }
  getOne (id) {
    
    return $axios.get('get-account-setup-question/' + id)
    .then((res) => {
      
      return res.data
    }).catch((error) => {
      
      throw error
    })
  }
  create (data) {
    return $axios.post(`create-account-setup-question`, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
  update (data) {
    return $axios.post(`update-account-setup-question`, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
  reorder (data) {
    return $axios.post(`reorder-account-setup-questions`, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
  delete (data) {
    return $axios.post(`delete-account-setup-question`, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new AccountSetupQuestionService()

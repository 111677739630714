<template>
  <v-container class="">
      <account-setup-question-form-view @submitForm="updateAccountSetupQuestion" v-if="accountSetupQuestion" :account-setup-question-prop="accountSetupQuestion"></account-setup-question-form-view>
  </v-container>
</template>

<script>
// import AppButton from '@/components/app/AppButton.vue'
import AccountSetupQuestionFormView from '@/views/account-setup/AccountSetupQuestionFormView'
import AccountSetupQuestionService from '@/services/account-setup-question.service'

export default {
  name: 'UpdateIntroScreenView',

  components: {
    AccountSetupQuestionFormView
  },

  data () {
    return {
      accountSetupQuestion: null
    }
  },

  computed: {},

  methods: {
    
    updateAccountSetupQuestion (data) {
      console.log(data)
      AccountSetupQuestionService.update(data).then((res) => {
        this.accountSetupQuestions = res

        this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Intro screen updated successfully.',
          duration: 4000,
          mode: 'success'
        })
        
        this.$router.push(`/account-setup`)
      })
    },
    loadIntroScreen(id){

      AccountSetupQuestionService.getOne(id).then((res) => {
        this.accountSetupQuestion = res
      })
    }
  },
  mounted () {
    this.loadIntroScreen(this.$route.params.id)
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
</style>

<template>
  <div class='chat'>
    <div ref='messages' class='messages'>
      <Message
          v-for='message in messages'
          :key='message.id'
          :class='["message", { right: message.is_sender }]'
          :dark='message.is_sender'
          :text='message.body'
          :author='message.author'
      />
    </div>

    <ChatBox
        class='chat-box'
        @submit='onSubmit'
    />
  </div>
</template>

<script>
import Message from "@/components/Message";
import ChatBox from "@/components/ChatBox";
import ChatService from "@/services/chat.service";
import { nextTick } from 'vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Chat',
  components: {
    ChatBox,
    Message
  },
  data: () => ({
    // conversation: null,
    messages: []
  }),
  // This is going to be called
  //  when the component gets rendered
  created() {
    this.getChat();

    setInterval(() => {
      this.getChat();
    }, 3000);
  },
  methods: {
    getChat() {

      ChatService.getConversation(this.$route.params.id).then((res) => {
        // console.log(res)
        // this.conversation = res;
        this.messages = res.messages;
        nextTick(() => {
          const element = this.$refs['messages'];
          element.scrollTo({ behavior: 'smooth', top: element.scrollHeight });
        });
      })
    },

    // This method will be called when a new message is sent
    onSubmit(event, text) {
      event.preventDefault();

      ChatService.sendMessage({conversation_id: this.$route.params.id, message: text}).then(() => {
        this.getChat();
        // this.conversation = res;
        // this.messages = res.messages;
      })
    }
  },
}
</script>

<style scoped>
.chat-box {
  width: 100%;
  display: flex;
}

/*input {*/
/*  width: min(100%, 20rem);*/
/*  flex-grow: 1;*/
/*}*/

/*button:disabled {*/
/*  opacity: 0.5;*/
/*}*/
.chat {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.messages {
  flex-grow: 1;
  overflow: auto;
  padding: 1rem;
}

.message + .message {
  margin-top: 1rem;
}

.message.right {
  margin-left: auto;
}
</style>

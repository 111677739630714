<template>
  <v-container class="">
    <v-row>
      <v-col cols="9">
        <span class="grey--text">Conversations</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>

        <v-row>
          <v-col cols="1">
            ID
          </v-col>
          <v-col cols="3">
            From
          </v-col>
<!--          <v-col cols="3">-->
<!--            Participants-->
<!--          </v-col>-->
          <v-col cols="3">
            Actions
          </v-col>
        </v-row>

            <v-row v-for="(conversation) in conversations" :key="conversation.id">
              <v-col cols="1">
                {{ conversation.id }}
              </v-col>
              <v-col cols="3">
                {{ conversation.from?.name }}
              </v-col>
<!--              <v-col cols="3">-->
<!--              </v-col>-->
              <v-col cols="3">
<!--                <v-row>-->
<!--                  <v-col cols="4">-->
                    <v-btn color="warning" @click="redirectToUpdate(conversation.id)">Join in</v-btn>
<!--                  </v-col>-->
<!--                </v-row>-->
              </v-col>
            </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChatService from '@/services/chat.service'

export default {
  name: 'ChatList',

  components: {
  },

  data () {
    return {
      conversations: null,
    }
  },

  computed: {},

  methods: {

    redirectToUpdate (id) {
      this.$router.push(`/conversation/` + id)
    },
    loadConversations () {
      ChatService.getConversations().then((res) => {
        this.conversations = res
      })
    },
  },
  created () {
    this.loadConversations()
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
</style>

import $axios from '../plugins/axios'

class AuthService {
  login (user) {
    return $axios.post(`login`, { email: user.email, password: user.password })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
  
  getMe () {
    
    return $axios.get('get-me')
    .then((res) => {
      
      return res.data
    }).catch((error) => {
  
      throw error
    })
  }

  requestResetPassword (data) {
    return $axios.post('/request-reset-password', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  resetPassword (data) {
    return $axios.post('/reset-password', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changePassword (data) {
    return $axios.post('/change-password', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new AuthService()

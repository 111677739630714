import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardView from '@/views/DashboardView.vue'
import AppLayoutContent from '@/AppLayoutContent'
import AuthLoginView from '@/views/auth/AuthLoginView'
// import AuthForgottenPasswordView from '@/views/auth/AuthForgottenPasswordView'
// import AuthResetPasswordView from '@/views/auth/AuthResetPasswordView'
import AppAuthLayoutContent from '@/AppAuthLayoutContent'
//
import guest from './middleware/guest'
import auth from './middleware/auth'
import store from '@/store'
//
import middlewarePipeline from "./middlewarePipeline"
import VerifyEmail from '@/views/VerifyEmail.vue'
import ListIntroScreensView
  from '@/views/intro-screens/ListIntroScreensView.vue'
import CreateIntroScreenView
  from '@/views/intro-screens/CreateIntroScreenView.vue'
import UpdateIntroScreenView
  from '@/views/intro-screens/UpdateIntroScreenView.vue'
import AccountSetupView from '@/views/account-setup/AccountSetupView'
import CreateAccountSetupQuestionView
  from '@/views/account-setup/CreateAccountSetupQuestionView'
import UpdateAccountSetupQuestionView
  from '@/views/account-setup/UpdateAccountSetupQuestionView'
import AuthResetPasswordView from '@/views/auth/AuthResetPasswordView'
import AuthForgottenPasswordView from '@/views/auth/AuthForgottenPasswordView'
import AuthSetPasswordSuccessView from '@/views/auth/AuthSetPasswordSuccessView'
import JourneyListView from '@/views/journeys/JourneyListView.vue'
import CreateJourneyView from '@/views/journeys/CreateJourneyView.vue'
import UpdateJourneyView from '@/views/journeys/UpdateJourneyView.vue'
import LessonListView from '@/views/lessons/LessonListView.vue'
import CreateLessonView from '@/views/lessons/CreateLessonView.vue'
import UpdateLessonView from '@/views/lessons/UpdateLessonView.vue'
import DestinationListView from '@/views/destinations/DestinationListView.vue'
import CreateDestinationView
  from '@/views/destinations/CreateDestinationView.vue'
import UpdateDestinationView
  from '@/views/destinations/UpdateDestinationView.vue'
import ChatList from "@/views/chat/ChatList";
import Chat from "@/views/chat/Chat";
// import UsersView from '@/views/UsersView'
// import PageNotFoundView from '@/views/PageNotFoundView'
// import AuthInvitationNewUserView from '@/views/auth/AuthInvitationNewUserView'
// import AuthSetPasswordSuccessView from '@/views/auth/AuthSetPasswordSuccessView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: AppLayoutContent,
    children: [
      {
        path: '',
        redirect: '/dashboard'
      },

      {
        path: '/dashboard',
        component: DashboardView,
          meta: {
            middleware: [auth]
          }
      },

      {
        path: '/intro-screens',
        name: 'list-intro-screens',
        component: ListIntroScreensView
      },

      {
        path: '/create-intro-screen',
        name: 'create-intro-screen',
        component: CreateIntroScreenView
      },

      {
        path: '/update-intro-screen/:id',
        name: 'update-intro-screen',
        component: UpdateIntroScreenView
      },
  
      {
        path: '/account-setup',
        name: 'account-setup',
        component: AccountSetupView
      },
  
      {
        path: '/create-account-setup-question',
        name: 'create-account-setup-question',
        component: CreateAccountSetupQuestionView
      },
  
      {
        path: '/update-account-setup-question/:id',
        name: 'update-account-setup-question',
        component: UpdateAccountSetupQuestionView
      },
  
      {
        path: '/destinations',
        name: 'destinations',
        component: DestinationListView
      },
  
      {
        path: '/create-destination',
        name: 'create-destination',
        component: CreateDestinationView
      },
  
      {
        path: '/update-destination/:id',
        name: 'update-destination',
        component: UpdateDestinationView
      },
  
      {
        path: '/journeys',
        name: 'journeys',
        component: JourneyListView
      },
  
      {
        path: '/create-journey',
        name: 'create-journey',
        component: CreateJourneyView
      },
  
      {
        path: '/update-journey/:id',
        name: 'update-journey',
        component: UpdateJourneyView
      },

      {
        path: '/lessons',
        name: 'lessons',
        component: LessonListView
      },
  
      {
        path: '/create-lesson',
        name: 'create-lesson',
        component: CreateLessonView
      },
  
      {
        path: '/update-lesson/:id',
        name: 'update-lesson',
        component: UpdateLessonView
      },

      {
        path: '/conversations',
        name: 'conversations',
        component: ChatList
      },

      {
        path: '/conversation/:id',
        name: 'conversation',
        component: Chat
      },

      // {
      //   path: '/users',
      //   name: 'users',
      //   component: UsersView
      // },
    ],
  },

  {
    path: '/login',
    component: AppAuthLayoutContent,
    children: [
      {
        path: '/login',
        component: AuthLoginView,
        meta: {
          middleware: [guest]
        }
      },
      
      {
        path: '/forgotten-password',
        component: AuthForgottenPasswordView,
        meta: {
          middleware: [guest]
        }
      },

      // {
      //   path: '/invitation/:id',
      //   component: AuthInvitationNewUserView,
      //   meta: {
      //     middleware: [guest]
      //   }
      // },

      {
        path: '/reset-password/:passwordToken',
        component: AuthResetPasswordView,
        meta: {
          middleware: [guest]
        }
      },

      {
        path: '/set-password-success',
        component: AuthSetPasswordSuccessView,
        meta: {
          middleware: [guest]
        }
      },
    ]
  },
  
  { path: '/verify-email', component: VerifyEmail },
  // { path: '*', redirect: '/404' }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store
  }

  return middleware[0]({ ...context, nextMiddleware: middlewarePipeline(context, middleware, 1) })
})

export default router

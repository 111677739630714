import $axios from '../plugins/axios'

class DestinationService {
	
	getAll () {
		
		return $axios.get('get-destinations').then((res) => {
			
			return res.data
		}).catch((error) => {
			
			throw error
		})
	}
	getOne (id) {
		
		return $axios.get('get-destination/' + id)
		.then((res) => {
			
			return res.data
		}).catch((error) => {
			
			throw error
		})
	}
	
	getAvailableJourneys (id) {
		
		return $axios.get('get-destination-available-journeys/' + id)
		.then((res) => {
			
			return res.data
		}).catch((error) => {
			
			throw error
		})
	}
	
	create (data) {
		return $axios.post(`create-destination`, data)
		.then((res) => {
			return res.data
		})
		.catch((error) => {
			throw error
		})
	}
	
	update (data) {
		return $axios.post(`update-destination`, data)
		.then((res) => {
			return res.data
		})
		.catch((error) => {
			throw error
		})
	}
	
	delete (data) {
		return $axios.post(`delete-destination`, data)
		.then((res) => {
			return res.data
		})
		.catch((error) => {
			throw error
		})
	}
}

export default new DestinationService()

import { render, staticRenderFns } from "./TheDefaultLayoutWrap.vue?vue&type=template&id=583ff7fd&scoped=true&"
import script from "./TheDefaultLayoutWrap.vue?vue&type=script&lang=js&"
export * from "./TheDefaultLayoutWrap.vue?vue&type=script&lang=js&"
import style0 from "./TheDefaultLayoutWrap.vue?vue&type=style&index=0&id=583ff7fd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "583ff7fd",
  null
  
)

export default component.exports
<template>
  <v-container class="">
    <v-row>
      <v-col cols="9">
        <span class="grey--text">Intro Screens</span>
      </v-col>
      <v-col cols="3">
        <v-btn @click="redirectToCreate">Create intro screen</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>

        <v-row v-for="(introScreen, index) in introScreens" :key="index">
          <v-col cols="1">
            {{ introScreen.id }}
          </v-col>
          <v-col cols="8">
            {{ introScreen.title }}
          </v-col>
          <v-col cols="3">
            <v-row>
              <v-col cols="4">
                <v-btn color="warning" @click="redirectToUpdate(introScreen.id)">Update</v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn color="error" @click="deleteIntroScreen(introScreen.id)">Delete</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import AppButton from '@/components/app/AppButton.vue'
import IntroScreenService from '@/services/intro-screen.service'

export default {
  name: 'ListIntroScreensView',

  components: {},

  data () {
    return {
      introScreens: null,
    }
  },

  computed: {},

  methods: {
    redirectToCreate () {
      this.$router.push(`/create-intro-screen`)
    },
    redirectToUpdate (id) {
      this.$router.push(`/update-intro-screen/` + id)
    },
    deleteIntroScreen (id) {
      if (confirm('Are you sure you want to delete this intro screen?')) {
        IntroScreenService.delete({id}).then(() => {

          this.$store.dispatch('snackbar/showSnackbarMessage', {
            message: 'Intro screen deleted successfully.',
            duration: 4000,
            mode: 'success'
          })
          
          this.loadIntroScreens()
        })
      }
    },
    loadIntroScreens () {
      IntroScreenService.getAll().then((res) => {
        this.introScreens = res
      })
    },
  },
  created () {
    this.loadIntroScreens()
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
</style>

<template>
  <v-container class="">
    <v-row>
      <v-col cols="9">
        <span class="grey--text">Journeys questions</span>
      </v-col>
      <v-col cols="3">
        <v-btn @click="redirectToCreate">Create journey</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>

        <v-row>
          <v-col cols="1">
            ID
          </v-col>
          <v-col cols="5">
            Name
          </v-col>
          <v-col cols="4">
            Actions
          </v-col>
        </v-row>

        <v-row v-for="(journey, index) in journeys" :key="index">
          <v-col cols="1">
            {{ journey.id }}
          </v-col>
          <v-col cols="5">
            {{ journey.name }}
          </v-col>
          <v-col cols="4">
            <v-row>
              <v-col cols="4">
                <v-btn color="warning" @click="redirectToUpdate(journey.id)">Update</v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn color="error" @click="deleteAccountSetup(journey.id)">Delete</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import AppButton from '@/components/app/AppButton.vue'
import JourneyService from '@/services/journey.service'

export default {
  name: 'JourneyListView',

  components: {},

  data () {
    return {
      journeys: null,
    }
  },

  computed: {},

  methods: {
    redirectToCreate () {
      this.$router.push(`/create-journey`)
    },
    redirectToUpdate (id) {
      this.$router.push(`/update-journey/` + id)
    },
    deleteAccountSetup (id) {
      if (confirm('Are you sure you want to delete this journey?')) {
        JourneyService.delete({id}).then(() => {

          this.$store.dispatch('snackbar/showSnackbarMessage', {
            message: 'Journey deleted successfully.',
            duration: 4000,
            mode: 'success'
          })
          
          this.loadJourneys()
        })
      }
    },
    loadJourneys () {
      JourneyService.getAll().then((res) => {
        this.journeys = res
      })
    },
  },
  created () {
    this.loadJourneys()
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
</style>

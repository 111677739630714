<template>
  <v-app id="app">
    <router-view />

    <app-snackbar />
  </v-app>
</template>

<script>
import AppSnackbar from "@/components/app/AppSnackbar"

export default {
  name: 'App',
  components: {
    AppSnackbar
  }

}
</script>

<style>
@font-face {
  font-family: 'Popping-SemiBolds';
}
</style>

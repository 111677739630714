<template>
  <div>
    <the-default-layout-wrap>
      <router-view></router-view>
    </the-default-layout-wrap>
  </div>
</template>

<script>
import TheDefaultLayoutWrap from "./views/layouts/TheDefaultLayoutWrap.vue"

export default {
  name: 'AppContent',
  components: {
    TheDefaultLayoutWrap
  },

  methods: {
  },

  async created () {
    //
  },

  async mounted () {
    //
  }
}
</script>

import UserService from "@/services/user.service"
import { actionStates } from '@/constants'
// import InvitationService from '@/services/invitation.service'

const state = {
  currentState: actionStates.INITIAL,
  user: {}
}

const getters = {
  getUser (state) {
    return state.user
  },

  getCurrentState (state) {
    return state.currentState
  },
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setUser (state, user) {
    state.user = user ?? []
  },
}

const actions = {
  async resetUser (vuexContext) {
    vuexContext.commit('setUser', {})
  },

  async fetchUser (vuexContext, id) {
    vuexContext.commit('setActionState', actionStates.LOADING)
    vuexContext.dispatch('resetUser')

    try {
      let response = await UserService.getOneUser(id)
      vuexContext.commit('setUser', response)

      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },

  async updateCurrentUser (vuexContext) {
    let currentUser = vuexContext.getters.getUser
    vuexContext.dispatch('fetchUser', currentUser.id)
  },

  async updateUserName (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await UserService.changeUserName(data)
      await vuexContext.dispatch('updateCurrentUser')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async updateUserPicture (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await UserService.changeUserImage(data)
      await vuexContext.dispatch('updateCurrentUser')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  // async deleteUser (vuexContext, data) {
  //   vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)
  //
  //   try {
  //     await UserService.deleteUser(data)
  //     vuexContext.commit('setActionState', actionStates.INITIAL)
  //   } catch (e) {
  //     vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
  //     throw e
  //   }
  // },

  // async inviteNewUser (vuexContext, data) {
  //   vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)
  //
  //   try {
  //     await UserService.inviteUser(data)
  //   } catch (e) {
  //     vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
  //     throw e
  //   }
  // },

  async getAllUsers (vuexContext) {
    vuexContext.commit('setActionState', actionStates.LOADING)
    vuexContext.dispatch('resetUser')

    try {
      let activeUsers = await UserService.getAllUsers()
      let invitedUsers = await UserService.getAllInvitedUsers()
      invitedUsers = invitedUsers.map(user => ({ ...user, pending: true }))

      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
      return [...activeUsers, ...invitedUsers]
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },

  // async checkInvitationExists (vuexContext, data) {
  //   return await UserService.checkInvitationExists(data)
  // },
  //
  // async acceptInvitation (vuexContext, invitationId) {
  //   await InvitationService.acceptInvitation({ invitation_id: invitationId})
  //   await vuexContext.dispatch('fetchUserOrganizations')
  // },
  //
  // async declineInvitation (vuexContext, invitationId) {
  //   await InvitationService.declineInvitation({ invitation_id: invitationId })
  //   await vuexContext.dispatch('fetchUserOrganizations')
  // },
  //
  // activateUser (vuexContext, data) {
  //   return UserService.activateUser(data).then((res) => {
  //     Vue.set(vuexContext.state.user, 'enabled', true)
  //     return res
  //   }).catch((e) => {
  //     throw e
  //   })
  // },

  // deactivateUser (vuexContext, data) {
  //   return UserService.deactivateUser(data).then((res) => {
  //     Vue.set(vuexContext.state.user, 'enabled', false)
  //     return res
  //   }).catch((e) => {
  //     throw e
  //   })
  // }
}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

/* eslint-disable */

export const hintConfirmPassword = 'Enter your password again.'

export const passInvalidErrMsg = `The password must be at least 6 characters long..`

export const passMismatchErrMsg = 'Passwords mismatch'
export const passRequiredErrMsg = 'Password is required'
export const confirmPassErrMsg = 'Confirm password is required'

/* eslint-disable */
export const invalidNewPassErrMSg = `The password must be at least 8 characters long, upper-and-lowercase letters,
      a number and a special symbol (coma,!,@,#,$,%,^,&,*,(,),_,+,|,<,>,?,\,/,",',-,=,(,),{,},[,],\`,~;€,$,№,).`

/* eslint-disable */
export const validPassRegex = new RegExp(/^(?=.*\p{Ll})(?=.*\p{Lu})(?=.*\p{Nd})(?=.*[!@#$%^&*()_+|<>?\/\\"'\-=(){}[\]`~;€№])[\p{L}\p{Nd}!@#$%^&*()_+|<>?\/\\"'\-=(){}[\]`~;€№]{8,}$/u, 'u')

<template>
  <v-container class="">
      <journey-form-view @submitForm="create"></journey-form-view>
  </v-container>
</template>

<script>
// import AppButton from '@/components/app/AppButton.vue'
import JourneyFormView from '@/views/journeys/JourneyFormView'
import JourneyService from '@/services/journey.service'

export default {
  name: 'CreateJourneyView',

  components: {
    JourneyFormView
  },

  data () {
    return {
    }
  },

  computed: {},

  methods: {

    create (data) {

      JourneyService.create(data).then(() => {

        this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Created successfully.',
          duration: 4000,
          mode: 'success'
        })
        
        this.$router.push(`/journeys`)
      })
    }
  },
  created () {
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
</style>

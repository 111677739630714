<template>
  <v-app light>
    <the-default-layout-wrap-app-bar @redirectToHomePage="redirectToHomePage" />

    <the-default-layout-wrap-menu-list
      v-if="menuItems"
      :items-prop="menuItems"
    />

    <v-main>
      <transition mode="out-in" name="scale">
        <v-container :key="$router.currentRoute.path" class="pa-10" fluid>
          <router-view></router-view>
        </v-container>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import TheDefaultLayoutWrapMenuList from './TheDefaultLayoutWrapMenuList.vue'
import TheDefaultLayoutWrapAppBar from '@/views/layouts/TheDefaultLayoutWrapAppBar'

export default {
  name: 'TheLayoutWrap',
  components: {
    TheDefaultLayoutWrapAppBar,
    TheDefaultLayoutWrapMenuList
  },

  data () {
    return {
      menuItems: [
        // {
        //   title: 'Layouts',
        //   to: '/layouts',
        //   icon: 'mdi-folder',
        //   class: 'nav-item'
        // },
        {
          title: 'Intro screens',
          to: '/intro-screens',
          icon: 'mdi-cellphone-text',
          class: 'nav-item'
        },
        {
          title: 'Account setup',
          to: '/account-setup',
          icon: 'mdi-account',
          class: 'nav-item'
        },
        {
          title: 'Destinations',
          to: '/destinations',
          icon: 'mdi-flag',
          class: 'nav-item'
        },
        {
          title: 'Journeys',
          to: '/journeys',
          icon: 'mdi-multicast',
          class: 'nav-item'
        },
        {
          title: 'Lessons',
          to: '/lessons',
          icon: 'mdi-notebook-heart',
          class: 'nav-item'
        },
        {
          title: 'Conversations',
          to: '/conversations',
          icon: 'mdi-notebook-heart',
          class: 'nav-item'
        },
      ]
    }
  },

  methods: {
    redirectToHomePage () {
      if (this.$route.path === '/dashboard') {
        return
      }

      this.$router.push(`/dashboard`)
    }
  }
}
</script>

<style lang="scss" scoped>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.4s ease;
}


.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>

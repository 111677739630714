import VueCookies from 'vue-cookies'
import AuthService from '@/services/auth.service'

const state = () => ({
  user: null
})

const getters = {
  isAuthenticated () {
    let cookie = VueCookies.get('current_user')

    return cookie != null
  },

  getCurrentUser (state) {
    return state.user
  }
}

const mutations = {
  setUser (state, user) {
    state.user = user
  },

  logout (state) {
    state.user = null
  },

  signOut (state) {
    state.user = null
    VueCookies.remove('current_user', null, process.env.VUE_APP_OMT_FRONT_END_BASE_URL)
  }
}

const actions = {
  getMe (vuexContext) {
    
    return AuthService.getMe().then(data => {
      
      vuexContext.commit('setUser', data)
    }).catch((error) => {
      vuexContext.dispatch('snackbar/showSnackbarMessage', {
          message: error,
          duration: 8000,
          mode: 'fail'
      })
    })
  },
  
  async login (vuexContext, data) {
    try {
      const response = await AuthService.login(data)

      if (data.isRememberMe) {
        VueCookies.set('current_user', response.access_token, '1Y', null, process.env.VUE_APP_OMT_FRONT_END_BASE_URL, false, 'Lax')
        vuexContext.commit('setUser', response.user)
        return
      }

      VueCookies.set('current_user', response.access_token, 0, null, process.env.VUE_APP_OMT_FRONT_END_BASE_URL, false, 'Lax')
      vuexContext.commit('setUser', response.user)
    } catch (error) {
      vuexContext.dispatch('snackbar/showSnackbarMessage', {
        message: error,
        duration: 8000,
        mode: 'fail'
      })
    }
  },
  
  async signOut (vuexContext) {
    vuexContext.commit('signOut')
  },

  // eslint-disable-next-line no-unused-vars
  requestResetPassword (_, data) {
    return AuthService.requestResetPassword(data).then((res) => {
      return res
    }).catch((e) => {
      throw e
    })
  },

  // eslint-disable-next-line no-unused-vars
  resetPassword (_, data) {
    return AuthService.resetPassword(data).then((res) => {
      return res
    }).catch((e) => {
      throw e
    })
  },

  // eslint-disable-next-line no-unused-vars
  changePassword (_, data) {
    return AuthService.changePassword(data).then((res) => {
      return res
    }).catch((e) => {
      throw e
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

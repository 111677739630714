import $axios from '../plugins/axios'

class IntroScreenService {
  
  getAll () {
    
    return $axios.get('get-intro-screens')
    .then((res) => {
      
      return res.data
    }).catch((error) => {
      
      throw error
    })
  }
  getOne (id) {
    
    return $axios.get('get-intro-screen/' + id)
    .then((res) => {
      
      return res.data
    }).catch((error) => {
      
      throw error
    })
  }
  create (data) {
    return $axios.post(`create-intro-screen`, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
  update (data) {
    return $axios.post(`update-intro-screen`, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
  delete (data) {
    return $axios.post(`delete-intro-screen`, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new IntroScreenService()

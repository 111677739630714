import { render, staticRenderFns } from "./UpdateLessonView.vue?vue&type=template&id=3483fde5&scoped=true&"
import script from "./UpdateLessonView.vue?vue&type=script&lang=js&"
export * from "./UpdateLessonView.vue?vue&type=script&lang=js&"
import style0 from "./UpdateLessonView.vue?vue&type=style&index=0&id=3483fde5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3483fde5",
  null
  
)

export default component.exports
<template>
  <v-container class="">
    <lesson-form-view @submitForm="create"></lesson-form-view>
  </v-container>
</template>

<script>
import LessonFormView from '@/views/lessons/LessonFormView.vue'
import LessonService from '@/services/lesson.service'

export default {
  name: 'CreateLessonView',

  components: {
    LessonFormView,
  },

  data () {
    return {}
  },

  computed: {},

  methods: {
    create (data) {

      let bodyFormData = new FormData()

      bodyFormData.set('name', data.name)
      bodyFormData.set('description', data.description)
      bodyFormData.set('duration', data.duration)

      for (let i = 0; i < data.content.length; i++) {

        if (data.content[i].title === null) {
          data.content[i].title = '';
        }
        if (data.content[i].text === null) {
          data.content[i].text = '';
        }

          bodyFormData.set('content[' + i + '][title]', data.content[i].title)
          bodyFormData.set('content[' + i + '][text]', data.content[i].text)
          bodyFormData.set('content[' + i + '][layout_id]', data.content[i].layout_id)
          bodyFormData.set('content[' + i + '][image]', data.content[i].image)
          bodyFormData.set('content[' + i + '][video]', data.content[i].video)
          bodyFormData.set('content[' + i + '][audio]', data.content[i].audio)
        if (data.content[i].choices){

          bodyFormData.set('content[' + i + '][choices]', JSON.stringify(data.content[i].choices))
        }
      }

      LessonService.create(bodyFormData).then(() => {

        this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Created successfully.',
          duration: 4000,
          mode: 'success',
        })

        this.$router.push(`/lessons`)
      })
    },
  },
  created () {
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
</style>

import $axios from '../plugins/axios'

class QuestionTypeService {
	
	getAll () {
		
		return $axios.get('get-question-types').then((res) => {
			
			return res.data
		}).catch((error) => {
			
			throw error
		})
	}
}

export default new QuestionTypeService()

<template>
  <v-container class="">
    <v-row>
      <v-col cols="9">
        <span class="grey--text">Destinations questions</span>
      </v-col>
      <v-col cols="3">
        <v-btn @click="redirectToCreate">Create destination</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>

        <v-row>
          <v-col cols="1">
            ID
          </v-col>
          <v-col cols="5">
            Name
          </v-col>
          <v-col cols="4">
            Actions
          </v-col>
        </v-row>

        <v-row v-for="(destination, index) in destinations" :key="index">
          <v-col cols="1">
            {{ destination.id }}
          </v-col>
          <v-col cols="5">
            {{ destination.name }}
          </v-col>
          <v-col cols="4">
            <v-row>
              <v-col cols="4">
                <v-btn color="warning" @click="redirectToUpdate(destination.id)">Update</v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn color="error" @click="deleteAccountSetup(destination.id)">Delete</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import AppButton from '@/components/app/AppButton.vue'
import DestinationService from '@/services/destination.service'

export default {
  name: 'DestinationListView',

  components: {},

  data () {
    return {
      destinations: null,
    }
  },

  computed: {},

  methods: {
    redirectToCreate () {
      this.$router.push(`/create-destination`)
    },
    redirectToUpdate (id) {
      this.$router.push(`/update-destination/` + id)
    },
    deleteAccountSetup (id) {
      if (confirm('Are you sure you want to delete this destination?')) {
        DestinationService.delete({id}).then(() => {

          this.$store.dispatch('snackbar/showSnackbarMessage', {
            message: 'Destination deleted successfully.',
            duration: 4000,
            mode: 'success'
          })
          
          this.loadDestinations()
        })
      }
    },
    loadDestinations () {
      DestinationService.getAll().then((res) => {
        this.destinations = res
      })
    },
  },
  created () {
    this.loadDestinations()
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
</style>

import $axios from '../plugins/axios'

class ChatService {
  
  getConversations () {
    
    return $axios.get('get-conversations')
    .then((res) => {
      
      return res.data
    }).catch((error) => {
      
      throw error
    })
  }
  getConversation (id) {
    
    return $axios.get('get-conversation/' + id)
    .then((res) => {
      
      return res.data
    }).catch((error) => {
      
      throw error
    })
  }
  sendMessage (data) {
    return $axios.post(`send-message`, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new ChatService()

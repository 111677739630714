<template>
  <v-container class="">

    <v-row>
      <v-col>
        <v-text-field
          id="name-field"
          v-model="title"
          clearable
          label="Title"
          required
        />
        <v-text-field
          id="name-field"
          v-model="text"
          clearable
          label="Text"
          required
        />
        <v-img width="300" v-if="file && file.url" :src=file.url></v-img>
        <v-file-input
          v-model="file"
          :append-icon="'mdi-camera'"
          :clearable="false"
          :prepend-icon="''"
          accept=".png, .jpg, .jpeg"
        />
        <div class="text-right">
          <v-btn @click="submitForm">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import AppButton from '@/components/app/AppButton.vue'

export default {
  name: 'IntroScreenFormView',

  components: {},
  props: {
    introScreenProp: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data () {
    return {
      id: null,
      title: null,
      text: null,
      file: null,
      layout_id: 1,
      updateMode: false,
    }
  },

  computed: {},

  methods: {
    submitForm () {

      this.$emit('submitForm', {
        id: this.id,
        title: this.title,
        text: this.text,
        file: this.file,
        layout_id: this.layout_id,
      })
    },
    updateFile(file){
      this.file = file
    }
  },
  created () {
    if (this.introScreenProp) {
      
      this.id = this.introScreenProp.id
      this.title = this.introScreenProp.title
      this.text = this.introScreenProp.text
      this.file = this.introScreenProp.file

      this.updateMode = true
    }
  },
}
</script>

<style lang="scss" scoped>
#login-btn {
  background: linear-gradient(to right, #387eff, #a34cff, #ff5798, #ff7a59);
}
</style>
